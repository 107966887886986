import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Story from '../components/story-card';

const Stories = (props) => {
    const { story: { edges } } = props.data;
    return (
        <Layout location={props.location}>
            <div className="container my-5">
                <h2 className="latest-heading pt-5 mb-4 text-center">
                    <span>القصص القصيرة</span>
                </h2>
                <p className="text-dark text-center mb-5">لقراءة القصة قم بتحميل ملف ال PDF</p>
                <div className="row justify-content-around">
                    {edges.map(({ node }) => {
                        return <Story story={node} key={node.title} />;
                    })}
                </div>
            </div>
            <Seo
                title={`القصص القصيرة`}
                keywords={[ 'الصحه العامه', 'الصحه النفسيه', 'شغبطه بقلم د.هبه اللكاوي', 'shaghbatta', 'قصص القصيرة' ]}
            />
        </Layout>
    );
};
export default Stories;
export const pageQuery = graphql`
    query StoriesQuery {
        story: allContentfulStory(sort: { fields: [publishedDate], order: DESC }) {
            edges {
                node {
                    title
                    publishedDate(formatString: "MMMM Do, YYYY")
                    author {
                        name
                    }
                    shortBio {
                        shortBio
                    }
                    pdf {
                        file {
                            url
                            fileName
                        }
                    }
                    heroImage {
                        gatsbyImageData(layout: FIXED, width: 80)
                    }
                }
            }
        }
    }
`;
